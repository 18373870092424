import { HttpClient } from '@angular/common/http';
import { ViewEncapsulation } from '@angular/compiler/src/compiler_facade_interface';
import { AfterViewInit, Component, OnInit } from '@angular/core';
import { DateAdapter } from '@angular/material/core';
import { Router } from '@angular/router';
import * as moment from 'moment';
import * as Chart from 'chart.js';
import { serviceCallArgument, WebService } from '../_communication/web-service';

@Component({
    selector: 'app-home-page',
    templateUrl: './home-page.component.html',
    styleUrls: ['./home-page.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
/** home-page component*/
export class HomePageComponent implements AfterViewInit {

    price_performance: string = "";
    date: string = "";
    data: string = "";
    other_total: string = "";
    selectedfrequency: string = "1y";
    weekly: string;
    month: string;
    priceChartCanvas: any;
    priceChartCtx: any;
    priceChartData: Chart;
    otherChartData: Chart;
    pagelanguageId: number;
    gridData: object[] = [];
    gridheaderData: object[] = [];
    gridDataOther: object[] = [];
    exceptionMessage: string = "";
    thousand: string = "";
    chartStatu: boolean = true;
    other_table_statu: boolean = true;
    currencySelectedItem: string;
    otherChartCanvas: any;
    otherChartCtx: any;

    constructor(private httpClient: HttpClient, private router: Router, private dateAdapter: DateAdapter<Date>) {
        this.currencySelectedItem = "Hepsi";
        moment.locale("tr");
        this.dateAdapter.setLocale('tr');
        let routingPath = this.router.url;
        this.pagelanguageId = 1;
        if (routingPath == "/priceperformance") {
            this.pagelanguageId = 2;
            this.dateAdapter.setLocale('en');
            moment.locale("en");
        }

        this.price_performance = this.pagelanguageId == 1 ? "Fiyat Performansı" : "Price Performance";
        this.weekly = this.pagelanguageId == 1 ? "h" : "w";
        this.month = this.pagelanguageId == 1 ? "a" : "m";
        this.date = this.pagelanguageId == 1 ? "Tarih" : "Date";
        this.data = this.pagelanguageId == 1 ? "Veri" : "Data";      
        this.GetPriceChart();
    }

    ngAfterViewInit() {

        this.priceChartCanvas = document.getElementById('priceChart');
        this.priceChartCanvas.height = 375;
        this.priceChartCtx = this.priceChartCanvas.getContext('2d');
        
        this.priceChartData = new Chart(this.priceChartCtx, {
            type: 'line',
            options: {
                legend: {
                    display: false
                },
                responsive: false,
                tooltips: {
                    mode: 'index',
                    intersect: false,
                },
                scales: {
                    yAxes: [{

                        gridLines: {
                            color: "#E1E2E2",
                        },
                        ticks: {
                            beginAtZero: true,
                            fontColor: "#000",
                            fontSize: 12,
                            padding: 15
                        }
                    }]
                },
                elements: {
                    point: {
                        radius: 0
                    }
                }

            }
        });

        var _this = this;
        $('#chart-dates li a').click(function (e) {
            $('#chart-dates li a').each(function () { $(this).removeClass("selected"); });
            $(this).addClass("selected");
            var value = $(this).data();
            _this.selectedfrequency = value.date;
            _this.GetPriceChart();
        });
    }

    sortBy(prop: string) {
        return this.gridData.sort((a, b) => a[prop] > b[prop] ? 1 : a[prop] === b[prop] ? 0 : -1);
    }

    gridShow() {
        if (this.chartStatu)
            this.chartStatu = false
        else
            this.chartStatu = true;
    }

    GetPriceChart() {
        var service = new WebService(this.httpClient, this.router);
        var args = new serviceCallArgument();
        args.Controller = "Snapshot";
        args.Action = "PriceChart";
        args.JPostContent = { frequency: this.selectedfrequency };

        args.FinallyHandler = (status, object) => {
            if (status == "success") {
                let data: any = [];
                let label: any = [];
                let values = JSON.parse(object.Data);
                //this.gridData = values;
                for (var i = 0; i < values.length; i++) {
                    data.push(values[i]["y"].toFixed(2));
                    label.push(new Date(values[i]["x"]).toISOString().split('T')[0]);
                }

                for (var k = values.length - 1; k > 0; k--) {
                    this.gridData.push({ x: values[k]["x"], y: values[k]["y"] });
                }
                this.priceChartData.data.labels = label;
                this.priceChartData.data.datasets = [{ data: data, borderColor: ['#1DFF00'], fill: false, borderWidth: 1 }];
                if (this.selectedfrequency == "1w" || this.selectedfrequency == "1m")
                    this.priceChartData.options.scales.xAxes = [{ type: 'time', distribution: 'linear', gridLines: { display: false }, ticks: { fontColor: "#000", fontSize: 13 }, time: { unit: 'day' } }];
                else
                    this.priceChartData.options.scales.xAxes = [{ type: 'time', distribution: 'linear', gridLines: { display: false }, ticks: { fontColor: "#000", fontSize: 13 }, time: { unit: 'month' } }];

                this.priceChartData.update();
            }
            else {
                this.exceptionMessage = object.Data;
            }
        };
        service.Post(args);
    }


}