import { Component } from '@angular/core';
import { serviceCallArgument, WebService } from '../_communication/web-service';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';

@Component({
    selector: 'app-file-upload-login',
    templateUrl: './file-upload-login.component.html',
    styleUrls: ['./file-upload-login.component.scss']
})
/** file-upload-login component*/
export class FileUploadLoginComponent {
    /** file-upload-login ctor */

    ShowErrorMessage: Boolean = false;
    ErrorHeader: string = "";
    ErrorMessage: string = "";

    constructor(private httpClient: HttpClient, private router: Router) {

    }

    CheckLogin() {
        this.ShowErrorMessage = false;
        this.ErrorHeader = "";
        this.ErrorMessage = "";

        let pass = (<HTMLInputElement>document.getElementById("password")).value;
        var service = new WebService(this.httpClient, this.router);
        var args = new serviceCallArgument();
        args.Controller = "Upload";
        args.Action = "LoginUpload";
        args.JPostContent = { password: pass };

        args.FinallyHandler = (status, object) => {
            if (status == "success") {                
                this.ShowErrorMessage = false;
                this.ErrorHeader = "";
                this.ErrorMessage = "";
                localStorage.setItem("loginKey", object.Data["Key"]);                
                this.router.navigate(['/fileupload']);
            }
            else {
                this.ShowErrorMessage = true;
                this.ErrorHeader = "Hatalı Şifre";
                this.ErrorMessage = "Lütfen tekrar giriş yapınız...";
            }
        };
        service.Post(args);
    }
}