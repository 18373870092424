import { HttpClient } from '@angular/common/http';
import { ViewEncapsulation } from '@angular/compiler/src/compiler_facade_interface';
import { AfterViewInit, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import * as Chart from 'chart.js';
import { serviceCallArgument, WebService } from '../_communication/web-service';
@Component({
    selector: 'app-performance',
    templateUrl: './performance.component.html',
    styleUrls: ['./performance.component.scss'],
    encapsulation: ViewEncapsulation.None,

})
/** performance component*/
export class PerformanceComponent implements OnInit, AfterViewInit {
    headerData: string;
    other: string;
    numberofroom: string;
    other_total: string;
    selectedCapacity: string;
    solidity_ratio_statu: boolean = true;
    price_per_room_statu: boolean = true;
    akfen_rental_income_statu: boolean = true;
    other_table_statu: boolean = true;
    solidity_ratio: string;
    price_per_room: string;
    akfen_rental_income: string;
    capacityChartData: Chart;
    priceChartData: Chart;
    rentChartData: Chart;
    otherChartData: Chart;
    capacityData: object[] = [];
    gridheaderData: object[] = [];
    roomCountData: object[] = [];
    capacityChartCanvas: any;
    capacityChartCtx: any;
    datas: object[] = [];
    priceChartCtx: any;
    priceChartCanvas: any;
    gridData: object[] = [];
    rentChartCanvas: any;
    rentChartCtx: any;
    otherChartCanvas: any;
    otherChartCtx: any;
    currencyData: object[] = [];
    currencySelectedItem: string;
    pageLanguageId: number;
    thousand: string = "";
    note: string = "";

    constructor(private router: Router, private httpClient: HttpClient) {

        let path = this.router.url;
        this.pageLanguageId = 1;
        if (path == "/performance")
            this.pageLanguageId = 2;

        this.currencyData = this.pageLanguageId == 1 ? [{ code: "Hepsi", name: "Hepsi" }, { code: "TRL", name: "Türk Lirası" }, { code: "USD", name: "Dolar" }, { code: "EUR", name: "Euro" }] : [{ code: "Hepsi", name: "All" }, { code: "TRL", name: "Turkish Lira" }, { code: "USD", name: "Dollar" }, { code: "EUR", name: "Euro" }];
        this.currencySelectedItem = "Hepsi";
        this.headerData = this.pageLanguageId == 1 ? "Operasyonel Performans" : "Operational Performance";
        this.solidity_ratio = this.pageLanguageId == 1 ? "% Doluluk Oranı" : "% Occupancy Rate";
        this.price_per_room = this.pageLanguageId == 1 ? "Oda Fiyatı (KDV Hariç) (Euro)" : "Average Daily Room Rate (Excluding VAT) (Euro) ";
        this.akfen_rental_income = this.pageLanguageId == 1 ? "Akfen Accor Otelleri Kira Geliri (Bin) (Euro)" : "Akfen Accor Hotels Rental Income (Thousand) (Euro)";
        this.other = this.pageLanguageId == 1 ? "Diğer" : "Other";
        this.numberofroom = this.pageLanguageId == 1 ? " Oda Sayısı" : "Number Of Room";
        //this.other_total = this.pageLanguageId == 1 ? " Akfen Toplam Kira Geliri (Euro)" : "Akfen Total Rental Income (Euro)";
        this.other_total = this.pageLanguageId == 1 ? " Akfen Toplam Kira Geliri (Euro)" : "Akfen Total Rental Income (Euro)";
        this.thousand = this.pageLanguageId == 1 ? " bin" : "thousand";
        this.note = this.pageLanguageId == 1 ? "* 1 aydan uzun vadede yerel para birimi ile tahsil edilen cari yıla ait kira gelirleri yayımlanırken önceki aylara ait gelirler güncel döviz kuruna göre güncellenmektedir." : "* Rent revenues collected in local currency and more than 1 month in terms are being updated according to current exchange rates while disclosing.";
        this.populateChart();

    }
    ngAfterViewInit() {
        this.populateCapacityChart();
        this.populatePriceChart();
        this.populateRentChart();
        this.populateOtherChart();
    }
    ngOnInit(): void {

    }

    onChangeCurrency() {
        this.populateChart();
    }

    number_format(number, decimals, dec_point, thousands_sep): string {

        number = (number + '').replace(',', '').replace(' ', '');
        var n = !isFinite(+number) ? 0 : +number,
            prec = !isFinite(+decimals) ? 0 : Math.abs(decimals),
            sep = (typeof thousands_sep === 'undefined') ? ',' : thousands_sep,
            dec = (typeof dec_point === 'undefined') ? '.' : dec_point,
            toFixedFix = function (n, prec) {
                var k = Math.pow(10, prec);
                return '' + Math.round(n * k) / k;
            };

        let s: string[] = [];

        s = (prec ? toFixedFix(n, prec) : '' + Math.round(n)).split('.');
        if (s[0].length > 3) {
            s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, sep);
        }
        if ((s[1] || '').length < prec) {
            s[1] = s[1] || '';
            s[1] += new Array(prec - s[1].length + 1).join('0');
        }
        return s.join(dec);
    }

    populateCapacityChart() {

        let _this = this;

        this.capacityChartCanvas = document.getElementById('capacityChart');
        this.capacityChartCtx = this.capacityChartCanvas.getContext('2d');

        this.capacityChartData = new Chart(this.capacityChartCtx, {
            type: 'line',
            options: {
                responsive: false,
                maintainAspectRatio: false,
                tooltips: {
                    mode: 'index',
                    intersect: false,
                    callbacks: {
                        label: function (tooltipItem, chart) {
                            var datasetLabel = chart.datasets[tooltipItem.datasetIndex].label || '';
                            return datasetLabel + ': ' + _this.number_format(tooltipItem.yLabel, 0, ".", ",");
                        }
                    }
                },
                scales: {
                    xAxes: [{
                        gridLines: {
                            display: false,
                        },
                        ticks: {
                            fontColor: "#000",
                            fontSize: 13,
                            precision: 0,
                        }
                    }],
                    yAxes: [{
                        gridLines: {
                            color: "#E1E2E2",
                        },
                        ticks: {
                            beginAtZero: true,
                            fontColor: "#000",
                            fontSize: 12,
                            padding: 15,
                        }

                    }]
                },
                elements: {
                    point: {
                        radius: 0
                    }
                }
            }
        });
    }

    populatePriceChart() {
        let _this = this;
        this.priceChartCanvas = document.getElementById('priceChart');
        this.priceChartCtx = this.priceChartCanvas.getContext('2d');
        this.priceChartData = new Chart(this.priceChartCtx, {
            type: 'line',
            options: {
                responsive: false,
                maintainAspectRatio: false,
                tooltips: {
                    mode: 'index',
                    intersect: false,
                    callbacks: {
                        label: function (tooltipItem, chart) {
                            var datasetLabel = chart.datasets[tooltipItem.datasetIndex].label || '';
                            return datasetLabel + ': ' + _this.number_format(tooltipItem.yLabel, 0, ".", ",");
                        }
                    }
                },
                scales: {
                    xAxes: [{
                        gridLines: {
                            display: false,
                        },
                        ticks: {
                            fontColor: "#000",
                            fontSize: 13,
                            precision: 0,
                        }
                    }],
                    yAxes: [{
                        gridLines: {
                            color: "#E1E2E2",
                        },
                        ticks: {
                            beginAtZero: true,
                            fontColor: "#000",
                            fontSize: 12,
                            padding: 15,

                        }

                    }]
                },
                elements: {
                    point: {
                        radius: 0
                    }
                }
            }
        });

    }

    populateRentChart() {
        let _this = this;
        this.rentChartCanvas = document.getElementById('rentChart');
        this.rentChartCtx = this.rentChartCanvas.getContext('2d');
        this.rentChartData = new Chart(this.rentChartCtx, {
            type: 'line',
            options: {
                responsive: false,
                maintainAspectRatio: false,
                tooltips: {
                    mode: 'index',
                    intersect: false,
                    callbacks: {
                        label: function (tooltipItem, chart) {
                            var datasetLabel = chart.datasets[tooltipItem.datasetIndex].label || '';
                            return datasetLabel + ': ' + _this.number_format(tooltipItem.yLabel, 0, ".", ",");
                        }
                    }
                },
                scales: {
                    xAxes: [{
                        gridLines: {
                            display: false,
                        },
                        ticks: {
                            fontColor: "#000",
                            fontSize: 13,
                            precision: 0,
                        }
                    }],
                    yAxes: [{
                        gridLines: {
                            color: "#E1E2E2",
                        },
                        ticks: {
                            beginAtZero: true,
                            fontColor: "#000",
                            fontSize: 12,
                            padding: 15,
                            callback: (value, index, values) =>{
                                if (parseInt(value.toString()) >= 1000) {
                                    return  value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                                }
                                else {
                                    return + value;
                                }
                            }
                        }

                    }]
                },
                elements: {
                    point: {
                        radius: 0
                    }
                }
            }
        });
    }

    populateOtherChart() {
        let _this = this;
        this.otherChartCanvas = document.getElementById('otherChart');
        this.otherChartCtx = this.otherChartCanvas.getContext('2d');
        this.otherChartData = new Chart(this.otherChartCtx, {
            type: 'line',
            options: {
                responsive: false,
                maintainAspectRatio: false,
                legend: {
                    display: false,
                },
                tooltips: {
                    mode: 'index',
                    intersect: false,
                    callbacks: {
                        label: function (tooltipItem, chart) {
                            var datasetLabel = chart.datasets[tooltipItem.datasetIndex].label || '';
                            return datasetLabel + ': ' + _this.number_format(tooltipItem.yLabel, 0, ".", ",");
                        }
                    }
                },
                scales: {
                    xAxes: [{
                        gridLines: {
                            display: false,
                        },
                        ticks: {
                            fontColor: "#000",
                            fontSize: 13,
                            precision: 0,
                        }
                    }],
                    yAxes: [{
                        gridLines: {
                            color: "#E1E2E2",
                        },
                        scaleLabel: {
                            display: true,
                            labelString: this.thousand
                        },
                        ticks: {

                            beginAtZero: true,
                            fontColor: "#000",
                            fontSize: 12,
                            padding: 15,
                            callback: (value, index, values) => {
                                if (parseInt(value.toString()) >= 1000) {
                                    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                                }
                                else {
                                    return + value;
                                }
                            }
                        }

                    }]
                },
                elements: {
                    point: {
                        radius: 0
                    }
                }
            }
        });
    }

    populateChart() {

        var service = new WebService(this.httpClient, this.router);
        var args = new serviceCallArgument();
        args.Controller = "Performance";
        args.Action = "CapacityChartData";
        args.JPostContent = { currency: this.currencySelectedItem };

        args.FinallyHandler = (status, object) => {
            console.log(object.Data);
            var datasetValue = [];
            if (status == "success") {

                var dynamicColors = function (name) {
                    if (name == "Turkey Ibis" || name == "Türkiye Ibis")
                        return "rgb(192,0,0)";
                    else if (name == "Turkey Novotel" || name == "Türkiye Novotel")
                        return "rgb(127,151,195)";
                    else if (name == "Russia Ibis" || name == "Rusya Ibis")
                        return "rgb(255,93,93)";
                    else if (name == "Tr & Ru Total" || name == "Tr & Ru Toplam")
                        return "rgb(64,64,64)";

                    var r = Math.floor(Math.random() * 255);
                    var g = Math.floor(Math.random() * 255);
                    var b = Math.floor(Math.random() * 255);
                    return "rgb(" + r + "," + g + "," + b + ")";
                }

                let other: any = [];
                let roomCount: any = [];
                let category: string = "";
                let data: any = [];
                let statu = true;
                let solidity_ratio: string;
                let price_per_room: string;
                let rental_income: string;
                let other_rental_income: string;
                let number_rooms: string;
                if (this.pageLanguageId == 1) {
                    solidity_ratio = "% Doluluk Oranı";
                    price_per_room = "Oda Başı Fiyat";
                    rental_income = "Kira Geliri";
                    other_rental_income = "Diğer Kira Gelirleri";
                    number_rooms = "Oda Sayısı";
                }
                else {
                    solidity_ratio = "Occ Rate";
                    price_per_room = "ADR";
                    rental_income = "Rental Income";
                    other_rental_income = "Other Rent Income";
                    number_rooms = "Number of Rooms";
                }

                jQuery.each(object.Data[0][solidity_ratio], function (i, value) {
                    let color: string = dynamicColors(i);
                    datasetValue.push(
                        {
                            fill: false,
                            borderColor: color,
                            backgroundColor: color,
                            borderWidth: 1,
                            lineTension: false,
                            label: i,
                            data: value,
                        })

                });
                if (datasetValue.length > 0) {
                    this.solidity_ratio_statu = true;
                    this.capacityChartData.data.labels = object.Data[0]["Header"];
                    this.capacityChartData.data.datasets = datasetValue;
                    this.capacityChartData.update();
                }
                else
                    this.solidity_ratio_statu = false;

                datasetValue = [];
                jQuery.each(object.Data[0][price_per_room], function (i, value) {
                    let color: string = dynamicColors(i);
                    datasetValue.push(
                        {
                            fill: false,
                            borderColor: color,
                            backgroundColor: color,
                            borderWidth: 1,
                            lineTension: false,
                            label: i,
                            data: value,
                        })

                });
                if (datasetValue.length > 0) {
                    this.price_per_room_statu = true;
                    this.priceChartData.data.labels = object.Data[0]["Header"];
                    this.priceChartData.data.datasets = datasetValue;
                    this.priceChartData.update();
                }
                else
                    this.price_per_room_statu = false;
                
                datasetValue = [];
                jQuery.each(object.Data[0][rental_income], function (i, value) {

                    for (var k = 0; k < value.length; k++) 
                        value[k] = value[k] / 1000;
                    

                    let color: string = dynamicColors(i);
                    datasetValue.push(
                        {
                            fill: false,
                            borderColor: color,
                            backgroundColor: color,
                            borderWidth: 1,
                            lineTension: false,
                            label: i,
                            data: value,
                        })

                });

                if (datasetValue.length > 0) {
                    this.akfen_rental_income_statu = true;
                    this.rentChartData.data.labels = object.Data[0]["Header"];
                    this.rentChartData.data.datasets = datasetValue;
                    this.rentChartData.update();
                }
                else
                    this.akfen_rental_income_statu = false;


                jQuery.each(object.Data[0]["Diğer Kira Gelirleri"],  (i, value) => {                  

                    other.push({ category: i, data: value });
                });

                if (other.length > 0) {
                   
                    for (var i = 0; i < object.Data[0]["otherChart"].length; i++) {
                        object.Data[0]["otherChart"][i] = object.Data[0]["otherChart"][i] / 1000;
                    }
                    let color: string = dynamicColors("Other");
                    this.other_table_statu = true;
                    this.gridData = other;
                    this.gridheaderData = object.Data[0]["HeaderOther"];
                    this.otherChartData.data.labels = object.Data[0]["HeaderOtherChart"];
                    this.otherChartData.data.datasets = [{ data: object.Data[0]["otherChart"], borderColor: ['#CC2D41'], backgroundColor: ['#CC2D41'], fill: false, borderWidth: 1, label: this.other_total }];
                    this.otherChartData.update();
                }
                else
                    this.other_table_statu = false;


                jQuery.each(object.Data[0][number_rooms], function (i, value) {
                    roomCount.push({ category: i.toString(), total: value[value.length - 1] })
                })

                this.roomCountData = roomCount;
            }
        };
        service.Post(args);
    }


}