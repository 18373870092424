import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';

@Component({
    selector: 'app-select-quarter',
    templateUrl: './select-quarter.component.html',
    styleUrls: ['./select-quarter.component.scss']
})
/** select-quarter component*/
export class SelectQuarterComponent {
    @Output() name = new EventEmitter<string>();
    @Input('selectedItem') selectedItem: string;
    @Input('title') title: string;
    @Input('data') data: any = [];

    constructor() {
        this.title = "";
    }
    ngOnInit(): void {
    }

    ngOnChanges(changes: SimpleChanges) {
    }

    populate() {
    }

    onChange(item: string) {
        this.name.emit(item);
    }
}