import { HttpClient } from '@angular/common/http';
import { ViewEncapsulation } from '@angular/compiler/src/compiler_facade_interface';
import { AfterViewInit, Component, OnInit } from '@angular/core';
import { DateAdapter } from '@angular/material/core';
import { Router } from '@angular/router';
import * as moment from 'moment';
import * as Chart from 'chart.js';
import { serviceCallArgument, WebService } from '../_communication/web-service';

@Component({
    selector: 'app-home-page-rental-income',
    templateUrl: './home-page-rental-income.component.html',
    styleUrls: ['./home-page-rental-income.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
/** home-page-rental-income component*/
export class HomePageRentalIncomeComponent implements AfterViewInit {
   
    other_total: string = "";
    otherChartData: Chart;
    pagelanguageId: number;  
    gridheaderData: object[] = [];
    gridDataOther: object[] = [];
    exceptionMessage: string = "";
    thousand: string = "";
    chartStatu: boolean = true;
    other_table_statu: boolean = true;
    currencySelectedItem: string;
    otherChartCanvas: any;
    otherChartCtx: any;

    constructor(private httpClient: HttpClient, private router: Router, private dateAdapter: DateAdapter<Date>) {
        this.currencySelectedItem = "Hepsi";
        moment.locale("tr");
        this.dateAdapter.setLocale('tr');
        let routingPath = this.router.url;
        this.pagelanguageId = 1;
        if (routingPath == "/rentalincome") {
            this.pagelanguageId = 2;
            this.dateAdapter.setLocale('en');
            moment.locale("en");
        }

        this.other_total = this.pagelanguageId == 1 ? " Akfen Toplam Kira Geliri (Euro)" : "Akfen Total Rental Income (Euro)";
        this.thousand = this.pagelanguageId == 1 ? " bin" : "thousand";
        this.populateChart();
    }

    ngAfterViewInit() {
        this.createOtherChart();
    }

    populateChart() {

        var service = new WebService(this.httpClient, this.router);
        var args = new serviceCallArgument();
        args.Controller = "Performance";
        args.Action = "CapacityChartData";
        args.JPostContent = { currency: this.currencySelectedItem };

        args.FinallyHandler = (status, object) => {
            var datasetValue = [];
            if (status == "success") {

                var dynamicColors = function (name) {
                    if (name == "Turkey Ibis" || name == "Türkiye Ibis")
                        return "rgb(192,0,0)";
                    else if (name == "Turkey Novotel" || name == "Türkiye Novotel")
                        return "rgb(127,151,195)";
                    else if (name == "Russia Ibis" || name == "Rusya Ibis")
                        return "rgb(255,93,93)";
                    else if (name == "Tr & Ru Total" || name == "Tr & Ru Toplam")
                        return "rgb(64,64,64)";

                    var r = Math.floor(Math.random() * 255);
                    var g = Math.floor(Math.random() * 255);
                    var b = Math.floor(Math.random() * 255);
                    return "rgb(" + r + "," + g + "," + b + ")";
                }

                let other: any = [];
                let roomCount: any = [];
                let category: string = "";
                let data: any = [];
                let statu = true;
                let rental_income: string;
                let other_rental_income: string;
                if (this.pagelanguageId == 1) {
                    other_rental_income = "Diğer Kira Gelirleri";
                }
                else {
                    other_rental_income = "Other Rent Income";
                }

                jQuery.each(object.Data[0]["Diğer Kira Gelirleri"], (i, value) => {

                    other.push({ category: i, data: value });
                });

                if (other.length > 0) {

                    for (var i = 0; i < object.Data[0]["otherChart"].length; i++) {
                        object.Data[0]["otherChart"][i] = object.Data[0]["otherChart"][i] / 1000;
                    }
                    let color: string = dynamicColors("Other");
                    this.other_table_statu = true;
                    this.gridDataOther = other;
                    this.gridheaderData = object.Data[0]["HeaderOther"];
                    this.otherChartData.data.labels = object.Data[0]["HeaderOtherChart"];
                    this.otherChartData.data.datasets = [{ data: object.Data[0]["otherChart"], borderColor: ['#CC2D41'], backgroundColor: ['#CC2D41'], fill: false, borderWidth: 1, label: this.other_total }];
                    this.otherChartData.update();
                }
                else
                    this.other_table_statu = false;


            }
        };
        service.Post(args);
    }

    createOtherChart() {
        let _this = this;
        this.otherChartCanvas = document.getElementById('otherChart');
        this.otherChartCtx = this.otherChartCanvas.getContext('2d');
        this.otherChartData = new Chart(this.otherChartCtx, {
            type: 'line',
            options: {
                responsive: false,
                maintainAspectRatio: false,
                legend: {
                    display: false,
                },
                tooltips: {
                    mode: 'index',
                    intersect: false,
                    callbacks: {
                        label: function (tooltipItem, chart) {
                            var datasetLabel = chart.datasets[tooltipItem.datasetIndex].label || '';
                            return datasetLabel + ': ' + _this.number_format(tooltipItem.yLabel, 0, ".", ",");
                        }
                    }
                },
                scales: {
                    xAxes: [{
                        gridLines: {
                            display: false,
                        },
                        ticks: {
                            fontColor: "#000",
                            fontSize: 13,
                            precision: 0,
                        }
                    }],
                    yAxes: [{
                        gridLines: {
                            color: "#E1E2E2",
                        },
                        scaleLabel: {
                            display: true,
                            labelString: this.thousand
                        },
                        ticks: {

                            beginAtZero: true,
                            fontColor: "#000",
                            fontSize: 12,
                            padding: 15,
                            callback: (value, index, values) => {
                                if (parseInt(value.toString()) >= 1000) {
                                    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                                }
                                else {
                                    return + value;
                                }
                            }
                        }

                    }]
                },
                elements: {
                    point: {
                        radius: 0
                    }
                }
            }
        });
    }

    number_format(number, decimals, dec_point, thousands_sep): string {

        number = (number + '').replace(',', '').replace(' ', '');
        var n = !isFinite(+number) ? 0 : +number,
            prec = !isFinite(+decimals) ? 0 : Math.abs(decimals),
            sep = (typeof thousands_sep === 'undefined') ? ',' : thousands_sep,
            dec = (typeof dec_point === 'undefined') ? '.' : dec_point,
            toFixedFix = function (n, prec) {
                var k = Math.pow(10, prec);
                return '' + Math.round(n * k) / k;
            };

        let s: string[] = [];

        s = (prec ? toFixedFix(n, prec) : '' + Math.round(n)).split('.');
        if (s[0].length > 3) {
            s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, sep);
        }
        if ((s[1] || '').length < prec) {
            s[1] = s[1] || '';
            s[1] += new Array(prec - s[1].length + 1).join('0');
        }
        return s.join(dec);
    }
}