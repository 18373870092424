import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';

@Component({
    selector: 'app-select',
    templateUrl: './select.component.html',
    styleUrls: ['./select.component.scss']
})

export class SelectComponent implements OnInit {
    @Output() name = new EventEmitter<string>();
    @Input('selectedItem') selectedItem: string;
    @Input('title') title: string;
    @Input('data') data: any = [];

    constructor() {
        this.title = "";
    }

    ngOnInit(): void {
        this.populate();
    }

    ngOnChanges(changes: SimpleChanges) {        
    }

    populate() {
    }

    onChange(item: string) {
        this.name.emit(item);
    }
}