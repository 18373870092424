import { OnInit } from '@angular/core';
import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { serviceCallArgument, WebService } from '../_communication/web-service';
import { KeyValue } from '@angular/common';

@Component({
    selector: 'app-portfolio',
    templateUrl: './portfolio.component.html',
    styleUrls: ['./portfolio.component.scss']
})
/** portfolio component*/
export class PortfolioComponent implements OnInit {
    objectKeys = Object.keys;
    headerData: string;
    portfolio: string;
    opening_date: string;
    operator: string;
    land_rental_term: string;
    total: string;
    capacity: string;
    date: string = "31.12.2020";
    valuation_amount: string;
    investment: string;
    portfolioitemList: object[] = [];
    pageLanguageId: number;
    rows: object[] = [];
    note: string = "";
    //note2:string = "";

    constructor(private httpClient: HttpClient, private router: Router) {

        let routingPath = this.router.url;
        this.pageLanguageId = 1;
        if (routingPath == "/portfolio")
            this.pageLanguageId = 2;

        this.headerData = this.pageLanguageId == 1 ? "Güncel Portföy Tablosu" : "Current Portfolio Table";
        this.portfolio = this.pageLanguageId == 1 ? "Portföy" : "Portfolio";
        this.opening_date = this.pageLanguageId == 1 ? "Açılış Tarihi" : "Opening Date";
        this.operator = this.pageLanguageId == 1 ? "İşletmeci" : "Operator";
        this.land_rental_term = this.pageLanguageId == 1 ? "Arsa Kira Vadesi" : "Land Rental Term";
        this.total = this.pageLanguageId == 1 ? "Toplam" : "Total";
        this.capacity = this.pageLanguageId == 1 ? "Kapasite" : "Capacity";
        this.valuation_amount = this.pageLanguageId == 1 ? "Değerleme Tutarı" : "Valuation Amount";
        this.investment = this.pageLanguageId == 1 ? "Yatırım" : "Investment";
        this.note = this.pageLanguageId == 1 ? "* Yukarıdaki veriler Akfen GYO'nun, bağlı ortaklıklarının ve iştiraklerinin Portföylerinde bulunan gayrimenkullerin ve projelerin değeridir." : "* The above data illustrates the value of the real estates and projects in the Portfolios of Akfen REIT, its subsidiaries and affiliates.";
        //this.note2 = this.pageLanguageId == 1 ? "** Söğütlüçeşme Projesi’ndeki değerler Akfen GYO’nun finansallarına %51 oranında konsolide edilmektedir." : "** The values in the Sogutlucesme Project are consolidated into Akfen REIT's consolidated financials by 51%.";

    }

    public keepOriginalOrder = (a, b) => a.key

    ngOnInit() {
        this.portfolioList();
    }
    portfolioList() {
        var service = new WebService(this.httpClient, this.router);
        var args = new serviceCallArgument();
        args.Controller = "Portfolio";
        args.Action = "GetAllData";
        args.JPostContent = {};

        args.FinallyHandler = (status, object) => {
            if (status == "success") {
                console.log(object.Data);
                this.portfolioitemList = object.Data
            }
            else {

            }
        };
        service.Post(args);
    }

    formatText(value: string) {
        return value.replace("m2", "㎡")
    }
}