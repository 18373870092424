import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpRequest, HttpHeaders, HttpEventType } from '@angular/common/http';
import { Router } from '@angular/router';

// declare var $: any;

@Injectable({ providedIn: 'root' })
export class WebService {

    constructor(private http: HttpClient, private router: Router) {

    }

    Post(callArgument) {
        if (callArgument == undefined)
            throw 'callArgument is undefined'

        if (callArgument.Controller == undefined)
            throw 'Controller is undefined'

        if (callArgument.Action == undefined)
            throw 'Action is undefined'

        if (callArgument.JPostContent == undefined) {
            throw 'JPostContent is undefined'
        }

        if (!JSON['dateParser']) {

            var reISO = /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2}(?:\.\d*))(?:Z|(\+|-)([\d|:]*))?$/;
            var reGlobal = /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2})?$/;
            var reMsAjax = /^\/Date\((d|-|.*)\)[\/|\\]$/;

            JSON['dateParser'] = function (key, value) {

                if (value === "NaN")
                    return null;

                if (typeof value === 'string') {
                    var a = reISO.exec(value);
                    if (a)
                        return new Date(value);

                    a = reGlobal.exec(value);
                    if (a)
                        return new Date(value);

                    a = reMsAjax.exec(value);
                    if (a) {
                        var b = a[1].split(/[-+,.]/);
                        return new Date(b[0] ? +b[0] : 0 - +b[1]);
                    }
                }
                return value;
            };
        }

        let httpApiServiceUrl = document.getElementsByTagName('base')[0].href + '/api' + '/' + callArgument.Controller + "/" + callArgument.Action;
        let routingPath = this.router.url;
        let pagelanguageId = 1;

        if (routingPath == "/snapshot")
            pagelanguageId = 2;
        else if (routingPath == "/financial")
            pagelanguageId = 2;
        else if (routingPath == "/performance")
            pagelanguageId = 2;
        else if (routingPath == "/portfolio")
            pagelanguageId = 2;

        this.HideElement(callArgument.ErrorViewElement);
        this.ShowElement(callArgument.SpinnerViewElement);

        var postData = { languageId: pagelanguageId };
        let jcontent = callArgument.JPostContent == undefined ? {} : callArgument.JPostContent;
        jcontent["languageId"] = pagelanguageId;

        let _header = new HttpHeaders({
            'Content-Type': 'application/json',
        });

        try {
            this.http.post(httpApiServiceUrl, JSON.stringify(jcontent), { responseType: callArgument.ResponseType, headers: _header })
                .subscribe(response => {
                    let hasError = false;
                    try {
                        var status = response['Status'];

                        if (status != undefined) {

                            if (status.toLowerCase() == "ok") {
                                this.FireOkHandler(callArgument, { Data: response["Data"] });
                            } else if (status.toLowerCase() == "exception") {
                                hasError = true;
                                this.FireErrorHandler(callArgument, 'service', { Data: response["Exception"] });
                            }
                            else {
                                throw 'unsupported status' + status.toLowerCase();
                            }
                        }
                    }
                    catch (e) {
                        hasError = true;
                    }
                    finally {

                        if (hasError) {
                            this.ShowElement(callArgument.ErrorViewElement);
                            this.FireFinalyHandler(callArgument, 'error', { NativeResponse: response, Data: response });
                        }
                        else {
                            this.FireFinalyHandler(callArgument, 'success', { NativeResponse: response, Data: response["Data"] });
                        }

                        this.HideElement(callArgument.SpinnerViewElement);
                    }
                })
        }
        catch (e) {            
        }
    }

    UploadPost(callArgument, data) {
        let httpApiServiceUrl = document.getElementsByTagName('base')[0].href + '/api' + '/' + callArgument.Controller + "/" + callArgument.Action;

        try {

            const uploadReq = new HttpRequest('POST', httpApiServiceUrl, data, {
                reportProgress: true,
            });

            this.http.request(uploadReq).subscribe(event => {
                if (event.type === HttpEventType.Response) {
                    if (event.status == 200)
                        this.FireFinalyHandler(callArgument, 'success', { NativeResponse: event, Data: event.statusText });
                    else
                        this.FireFinalyHandler(callArgument, 'error', { NativeResponse: event, Data: event.statusText });
                }
                else if (event.type == HttpEventType.ResponseHeader) {
                    if (event.status == 500)
                        this.FireFinalyHandler(callArgument, 'error', { NativeResponse: event, Data: "Hatalı dosya lütfen kontrol edin." });
                }

            });
        }
        catch (e) {            
        }
    }

    HideElement(element) {

        if (element == undefined)
            return;

        try { element.css('display', 'none'); } catch (e) { }
    }

    ShowElement(element) {
        if (element == undefined)
            return;

        try { element.css('display', 'inline-block'); } catch (e) { }
    }

    FireErrorHandler(serviceCallArgument, type, object) {
        try { serviceCallArgument.ErrorHandler(type, object); } catch (e) { }
    };

    FireOkHandler(serviceCallArgument, data) {
        try { serviceCallArgument.OkHandler(data); } catch (e) { }
    };

    FireFinalyHandler(serviceCallArgument, type, object) {
        try { serviceCallArgument.FinallyHandler(type, object); } catch (e) { }
    };

    FireTokenExpireHandler() {
        try {
            localStorage.clear();
            this.router.navigateByUrl('/');

        } catch (e) {
            console.log('FireTokenExpireHandler - broadcast', e);
        }
    };
}

export class serviceCallArgument {
    Controller = undefined;
    Action = undefined;
    JPostContent = undefined;
    SpinnerViewElement = undefined;
    ErrorViewElement = undefined;

    constructor() { }

    OkHandler() { };

    ErrorHandler() { };

    FinallyHandler(status, object) { };
}
