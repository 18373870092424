import { Component, Inject, OnInit, ViewChild, ViewEncapsulation, TemplateRef } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { DateAdapter } from '@angular/material/core';
import { CompanyCard, IndexWeight, News, NewsDetail } from '../_model';
import { serviceCallArgument, WebService } from '../_communication/web-service';
import * as Chart from 'chart.js';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import "moment/locale/tr";

@Component({
    selector: 'app-equity-snapshot',
    templateUrl: './equity-snapshot.component.html',
    providers: [NgbModal],
    styleUrls: ['./equity-snapshot.component.scss'],
    encapsulation: ViewEncapsulation.None,
})

export class EquitySnapshotComponent implements OnInit {
    companyCard: BehaviorSubject<CompanyCard>;
    indexWeight: BehaviorSubject<IndexWeight>;
    news: BehaviorSubject<News>;
    newsDetail: BehaviorSubject<NewsDetail>;
    companyCardDate: string;
    SelectedStartDate = moment(new Date()).add('days', -30).format("YYYY-MM-DD");
    SelectedEndDate = moment(new Date()).format("YYYY-MM-DD");
    weekly: string;
    month: string;
    headerData: string = "";
    price_performance: string = "";
    date: string = "";
    data: string = "";
    cash_asset: string = "";
    nav_prem: string = "";
    weight: string = "";
    index_coverage: string = "";
    index: string = "";
    company_information: string = "";
    news_detail: string = "";
    close: string = "";
    kap_news: string = "";
    companyCardData: any = [];
    indexWeightData: any = [];
    gridData: object[] = [];
    newsData: any = [];
    newsDatas: any = [];
    newsDetailData: any = [];
    chartStatu: boolean = true;
    exceptionMessage: string = "";
    note: string = "";
    snapshotnote: string = "";
    selectedfrequency: string = "1y";
    priceChartCanvas: any;
    priceChartCtx: any;
    priceChartData: Chart;
    pagelanguageId: number;
    netAssetChartCanvas: any;
    netAssetChartCtx: any;
    netAssetChartData: Chart;
    currencySelectedItem: string;
    currency: string;
    currencyData: object[] = [];
    newsDataStatu = true;
    copyRightStatu = true;
    @ViewChild('content') modalContent: TemplateRef<any>;

    constructor(private httpClient: HttpClient, private router: Router, private modalService: NgbModal, private dateAdapter: DateAdapter<Date>) {
        moment.locale("tr");
        this.companyCardDate = moment(new Date()).format("DD.MM.YYYY")
        this.dateAdapter.setLocale('tr');

        let routingPath = this.router.url;

        this.pagelanguageId = 1;


        this.currencyData = this.pagelanguageId == 1 ? [{ code: "TRL", name: "Türk Lirası" }, { code: "USD", name: "Dolar" }, { code: "EUR", name: "Euro" }] : [{ code: "TRL", name: "Turkish Lira" }, { code: "USD", name: "Dollar" }, { code: "EUR", name: "Euro" }];
        this.currencySelectedItem = "EUR";

        if (routingPath == "/snapshot") {
            this.pagelanguageId = 2;
            this.newsDataStatu = false;
            this.copyRightStatu = false;
            this.companyCardDate = moment(new Date()).format("MM/DD/YYYY")
            this.dateAdapter.setLocale('en');
            moment.locale("en");
            this.currencySelectedItem = "EUR";
        }

        this.headerData = this.pagelanguageId == 1 ? "Hisse Piyasa Verileri" : "Stock Market Data";
        this.nav_prem = this.pagelanguageId == 1 ? "Nakit Aktif Değere Göre Prim/ İskonto Oranı (%)" : "Nav Prem./Disc (%)";
        this.price_performance = this.pagelanguageId == 1 ? "Fiyat Performansı" : "Price Performance";
        this.date = this.pagelanguageId == 1 ? "Tarih" : "Date";
        this.data = this.pagelanguageId == 1 ? "Veri" : "Data";
        //this.cash_asset = this.pagelanguageId == 1 ? "Nakit Aktif Değere Göre Prim/ İskonto Oranı" : "Net Asset Value";
        this.index_coverage = this.pagelanguageId == 1 ? "Endeks Kapsam Bilgisi" : "Index Coverage";
        this.index = this.pagelanguageId == 1 ? "Endeks" : "Index";
        this.weight = this.pagelanguageId == 1 ? "Ağırlık" : "Weight";
        this.news_detail = this.pagelanguageId == 1 ? "Haber Detayı" : "News Detail";
        this.close = this.pagelanguageId == 1 ? "Kapat" : "Close";
        this.company_information = this.pagelanguageId == 1 ? "Şirket Bilgileri" : "Company Information";
        this.kap_news = this.pagelanguageId == 1 ? "KAP Haberleri" : "KAP News";
        this.note = this.pagelanguageId == 1 ? " * Finansal veriler son yayımlanan konsolide finansal tabloları esas almaktadır." : "* Financial data is based on the latest published consolidated financial statements.";
        this.snapshotnote = this.pagelanguageId == 1 ? " ** Tüm günlük veriler bir önceki günün kapanış verilerinden hesaplanmaktadır." : "** Daily data are calculated from the previous day's closing.";

        this.weekly = this.pagelanguageId == 1 ? "h" : "w";
        this.month = this.pagelanguageId == 1 ? "a" : "m";
        this.currency = this.pagelanguageId == 1 ? "Para Birimi" : "Currency";

        this.GetCompanyCard();
        this.GetIndexWeight();
        this.GetPriceChart();
        this.GetNavChart();
        this.GetNews(true);

    }

    ngOnInit() {
        this.netAssetChart();
    }

    ngAfterViewInit() {
        this.priceChartCanvas = document.getElementById('priceChart');
        this.priceChartCanvas.height = 300;
        this.priceChartCtx = this.priceChartCanvas.getContext('2d');
        this.priceChartData = new Chart(this.priceChartCtx, {
            type: 'line',
            options: {
                legend: {
                    display: false
                },
                responsive: false,
                tooltips: {
                    mode: 'index',
                    intersect: false,
                },
                scales: {
                    yAxes: [{

                        gridLines: {
                            color: "#E1E2E2",
                        },
                        ticks: {
                            beginAtZero: true,
                            fontColor: "#000",
                            fontSize: 12,
                            padding: 15
                        }
                    }]
                },
                elements: {
                    point: {
                        radius: 0
                    }
                }

            }
        });

        var _this = this;
        $('#chart-dates li a').click(function (e) {
            $('#chart-dates li a').each(function () { $(this).removeClass("selected"); });
            $(this).addClass("selected");
            var value = $(this).data();
            _this.selectedfrequency = value.date;
            _this.GetPriceChart();
        });
    }

    netAssetChart() {
        this.netAssetChartCanvas = document.getElementById('netAssetChart');
        this.netAssetChartCtx = this.netAssetChartCanvas.getContext('2d');

        this.netAssetChartData = new Chart(this.netAssetChartCtx, {
            type: 'line',
            options: {
                responsive: true,
                maintainAspectRatio: false,
                legend: {
                    display: false
                },
                tooltips: {
                    mode: 'index',
                    intersect: false,
                },
                scales: {
                    xAxes: [{
                        gridLines: {
                            display: false,
                        },
                        ticks: {
                            fontColor: "#000",
                            fontSize: 13
                        }
                    }],
                    yAxes: [{
                        gridLines: {
                            color: "#E1E2E2",
                        },
                        ticks: {
                            beginAtZero: true,
                            fontColor: "#000",
                            fontSize: 12,
                            padding: 15
                        }

                    }]
                },
                elements: {
                    point: {
                        radius: 0
                    }
                }
            }
        });
    }

    onSelectedStartDate() {
        this.GetNews(false);
    }
    onSelectedEndDate() {
        this.GetNews(false);
    }

    onChangeCurrency() {
        this.GetNavChart();
    }


    gridShow() {
        if (this.chartStatu)
            this.chartStatu = false
        else
            this.chartStatu = true;
    }

    GetCompanyCard() {
        var service = new WebService(this.httpClient, this.router);
        var args = new serviceCallArgument();
        args.Controller = "Snapshot";
        args.Action = "CompanyCard";
        args.JPostContent = {};

        args.FinallyHandler = (status, object) => {
            if (status == "success") {
                this.companyCard = new BehaviorSubject<CompanyCard>(JSON.parse(object.Data));
                this.companyCardData = this.companyCard.value;
            }
            else {
                this.exceptionMessage = object.Data;
            }
        };
        service.Post(args);
    }

    GetIndexWeight() {
        var service = new WebService(this.httpClient, this.router);
        var args = new serviceCallArgument();
        args.Controller = "Snapshot";
        args.Action = "IndexWeight";
        args.JPostContent = {};

        args.FinallyHandler = (status, object) => {
            if (status == "success") {
                this.indexWeight = new BehaviorSubject<IndexWeight>(JSON.parse(object.Data));
                this.indexWeightData = this.indexWeight.value;
            }
            else {
                this.exceptionMessage = object.Data;
            }
        };
        service.Post(args);
    }

    GetNews(first: boolean) {
        var service = new WebService(this.httpClient, this.router);
        var args = new serviceCallArgument();
        args.Controller = "Snapshot";
        args.Action = "News";
        args.JPostContent = { date: this.SelectedStartDate, enddate: this.SelectedEndDate, isFirst: first };

        args.FinallyHandler = (status, object) => {
            if (status == "success") {
                this.news = new BehaviorSubject<News>(JSON.parse(object.Data));

                this.newsDatas = this.news.value;
                for (var i = 0; i < this.newsDatas.length; i++) {
                    this.newsDatas[i]["date"] = moment(this.newsDatas[i]["date"]).add(3, 'hours');
                }
                this.newsData = this.newsDatas;

            }
            else {
                this.exceptionMessage = object.Data;
            }
        };
        service.Post(args);
    }

    GetNewsDetail(date: object, header: string, newsId: string) {
        this.modalService.open(this.modalContent, { windowClass: 'news-detail-content' })
        document.getElementById('newsModalBody').innerHTML = '';
        document.getElementById('newsModalHeader').innerHTML = header;
        if (this.pagelanguageId == 1)
            document.getElementById('newsModalHeaderDate').innerHTML = moment.utc(date).local().format('DD.MM.YYYY HH:mm:ss');
        else
            document.getElementById('newsModalHeaderDate').innerHTML = moment.utc(date).local().format('MM/DD/YYYY HH:mm:ss');


        var service = new WebService(this.httpClient, this.router);
        var args = new serviceCallArgument();
        args.Controller = "Snapshot";
        args.Action = "NewsDetail";
        args.JPostContent = { id: newsId };

        args.FinallyHandler = (status, object) => {
            if (status == "success") {
                this.newsDetail = new BehaviorSubject<NewsDetail>(JSON.parse(object.Data));
                this.newsDetailData = this.newsDetail.value;

                var content = this.newsDetailData[0]["body"];
                var parser = new DOMParser();
                var htmlDoc = parser.parseFromString(content, 'text/html');

                $('.fa-question-circle', htmlDoc).css('display', 'none');
                $('iframe', htmlDoc).each(function (index, element) {
                    var iframe = $(this)[index];

                    var iframeSrc = $(iframe).attr('src');

                    if (iframeSrc.indexOf('embed') == -1) {
                        if (iframeSrc.indexOf('youtu.be') != -1) {
                            let youtubeSrcId = iframeSrc.split('/')[iframeSrc.split('/').length - 1];

                            $(iframe).attr('src', 'https://www.youtube.com/embed/' + youtubeSrcId);
                        } else if (iframeSrc.indexOf('youtube.com') != -1) {
                            var youtubeParams = iframeSrc.split('?')[1];

                            var urlParams = new URLSearchParams(youtubeParams);
                            let youtubeSrcId = urlParams.get('v');

                            $(iframe).attr('src', 'https://www.youtube.com/embed/' + youtubeSrcId);
                        }
                    }
                });

                var stringHtmlContent = $('body', htmlDoc).html();

                if (stringHtmlContent.substring(0, 5) == '<?xml') {
                    $('#newsModalBody').append(stringHtmlContent.split('</head>')[1]);
                    $('#newsModalBody .fa-question-circle').remove();
                    $('#newsModalBody .gwt-Label').css('text-align', 'left');
                } else {
                    $('#newsModalBody').append(stringHtmlContent);
                }
            }
            else {
                this.exceptionMessage = object.Data;
            }
        };
        service.Post(args);


    }

    sortBy(prop: string) {
        return this.gridData.sort((a, b) => a[prop] > b[prop] ? 1 : a[prop] === b[prop] ? 0 : -1);
    }

    GetPriceChart() {
        var service = new WebService(this.httpClient, this.router);
        var args = new serviceCallArgument();
        args.Controller = "Snapshot";
        args.Action = "PriceChart";
        args.JPostContent = { frequency: this.selectedfrequency };

        args.FinallyHandler = (status, object) => {
            if (status == "success") {
                let data: any = [];
                let label: any = [];
                this.gridData = [];
                let values = JSON.parse(object.Data);
                //this.gridData = values;
                for (var i = 0; i < values.length; i++) {
                    data.push(values[i]["y"].toFixed(2));
                    label.push(moment(new Date(values[i]["x"].toString().split('T')[0])).format("yyyy-MM-DD"));
                }

                for (var k = values.length - 1; k > -1; k--) {
                    this.gridData.push({ x: values[k]["x"], y: values[k]["y"] });
                }
                this.priceChartData.data.labels = label;
                this.priceChartData.data.datasets = [{ data: data, borderColor: ['#1DFF00'], fill: false, borderWidth: 1 }];
                if (this.selectedfrequency == "1w" || this.selectedfrequency == "1m")
                    this.priceChartData.options.scales.xAxes = [{ type: 'time', distribution: 'linear', gridLines: { display: false }, ticks: { fontColor: "#000", fontSize: 13 }, time: { unit: 'day' } }];
                else
                    this.priceChartData.options.scales.xAxes = [{ type: 'time', distribution: 'linear', gridLines: { display: false }, ticks: { fontColor: "#000", fontSize: 13 }, time: { unit: 'month' } }];

                this.priceChartData.update();
            }
            else {
                this.exceptionMessage = object.Data;
            }
        };
        service.Post(args);
    }



    GetNavChart() {
        var service = new WebService(this.httpClient, this.router);
        var args = new serviceCallArgument();
        args.Controller = "Snapshot";
        args.Action = "NavChart";
        args.JPostContent = { currency: this.currencySelectedItem };

        args.FinallyHandler = (status, object) => {
            if (status == "success") {
                let data: any = [];
                let label: any = [];
                let values = JSON.parse(object.Data);
                let pageTitle: string = "";
                for (var i = 0; i < values.length; i++) {
                    pageTitle = values[i]["fielad"];
                    data.push(values[i]["value"].toFixed(0));
                    label.push(values[i]["year"]);
                }
                this.cash_asset = pageTitle;
                this.netAssetChartData.data.labels = label;
                this.netAssetChartData.data.datasets = [{ data: data, borderColor: ['#D5B555'], fill: false, borderWidth: 1, label: pageTitle }];
                this.netAssetChartData.update();
            }
            else {
                this.exceptionMessage = object.Data;
            }
        };
        service.Post(args);
    }

    public lineChartOptions = {
        scaleShowVerticalLines: false,
        responsive: true
    };
    public lineChartLabels = ['2000', '2001', '2002', '2003', '2004', '2005', '2006'];
    public lineChartType = 'line';
    public lineChartLegend = true;
    public lineChartData = [
        { data: [75, 49, 89, 31, 86, 35, 50], label: 'Series A' },
        { data: [48, 38, 65, 39, 66, 17, 80], label: 'Series B' }
    ];
}