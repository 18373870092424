import { Component, Inject, AfterViewInit, ViewChild, ViewEncapsulation, TemplateRef, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { serviceCallArgument, WebService } from '../_communication/web-service';

@Component({
    selector: 'app-file-upload',
    templateUrl: './file-upload.component.html',
    styleUrls: ['./file-upload.component.scss']
})

export class FileUploadComponent implements AfterViewInit, OnInit {

    ShowSuccessMessage: Boolean = false;
    ShowErrorMessage: Boolean = false;
    SuccessHeader: string = "";
    SuccessMessage: string = "";
    ErrorHeader: string = "";
    ErrorMessage: string = "";

    constructor(private httpClient: HttpClient, private router: Router) {
        var loginKey = localStorage.getItem('loginKey');

        if (loginKey == undefined || loginKey == "undefined")
            this.router.navigate(['/fileuploadlogin']);

        localStorage.clear();
    }

    ngOnInit() {
        ($("#input-b1") as any).fileinput({
            language: "tr",
            uploadAsync: true,
            fileActionSettings: {
                showUpload: false,
                showZoom: false,
            },
            allowedFileExtensions: ["xlsx", "xls"]
        });

        ($("#input-b2") as any).fileinput({
            language: "tr",
            uploadAsync: true,
            fileActionSettings: {
                showUpload: false,
                showZoom: false,
            },
            allowedFileExtensions: ["xlsx", "xls"]
        });

        ($("#input-b3") as any).fileinput({
            language: "tr",
            uploadAsync: true,
            fileActionSettings: {
                showUpload: false,
                showZoom: false,
            },
            allowedFileExtensions: ["xlsx", "xls"]
        });

        ($("#input-b4") as any).fileinput({
            language: "tr",
            uploadAsync: true,
            fileActionSettings: {
                showUpload: false,
                showZoom: false,
            },
            allowedFileExtensions: ["xlsx", "xls"]
        });
    }

    ngAfterViewInit() {
        $("body").on("click", "#tab-ul li", function (e) {
            $('#tab-ul li').each(function () {
                $(this).removeClass("active");
            });

            $(this).addClass("active");

            this.ClearMessage();
        });
    }

    ClearMessage() {
        this.ShowSuccessMessage = false;
        this.ShowErrorMessage = false;
        this.SuccessHeader = "";
        this.SuccessMessage = "";
        this.ErrorHeader = "";
        this.ErrorMessage = "";
    }

    FreeFloatUpload() {
        debugger;
        this.ClearMessage();
        let inputFiles = ($("#input-b4") as any).fileinput();
        let data = inputFiles[0].files[0]

        if (data.name != "FreeFloat.xlsx") {
            this.ShowErrorMessage = true;
            this.ErrorHeader = "Dosya aktarımı Başarısız.";
            this.ErrorMessage = "Dosya ismini kontrol ediniz.";
        }
        else {
            const formData = new FormData();
            formData.append('file', data);

            var service = new WebService(this.httpClient, this.router);
            var args = new serviceCallArgument();
            args.Controller = "Upload";
            args.Action = "FreeFloat";

            args.FinallyHandler = (status, object) => {
                if (status == "success") {
                    this.ShowSuccessMessage = true;
                    this.SuccessHeader = "Dosya aktarımı Başarılı.";
                    this.SuccessMessage = "Sayfada güncel dataya ulaşabilirsiniz...";
                }
                else {
                    this.ShowErrorMessage = true;
                    this.ErrorHeader = "Dosya aktarımı Başarısız.";
                    this.ErrorMessage = object.Data;
                }

                let inputFiles2 = ($("#input-b4") as any).fileinput();
                inputFiles2.fileinput('clear');
            };
            service.UploadPost(args, formData);
        }
    }

    NavUpload() {
        this.ClearMessage();
        let inputFiles = ($("#input-b1") as any).fileinput();
        let data = inputFiles[0].files[0]
        if (data.name != "NAVTemplate.xlsx") {
            this.ShowErrorMessage = true;
            this.ErrorHeader = "Dosya aktarımı Başarısız.";
            this.ErrorMessage = "Dosya ismini kontrol ediniz.";
        }
        else {
            const formData = new FormData();
            formData.append('file', data);

            var service = new WebService(this.httpClient, this.router);
            var args = new serviceCallArgument();
            args.Controller = "Upload";
            args.Action = "NavFile";

            args.FinallyHandler = (status, object) => {
                if (status == "success") {
                    this.ShowSuccessMessage = true;
                    this.SuccessHeader = "Dosya aktarımı Başarılı.";
                    this.SuccessMessage = "Sayfada güncel dataya ulaşabilirsiniz...";
                }
                else {
                    this.ShowErrorMessage = true;
                    this.ErrorHeader = "Dosya aktarımı Başarısız.";
                    this.ErrorMessage = object.Data;
                }

                let inputFiles2 = ($("#input-b1") as any).fileinput();
                inputFiles2.fileinput('clear');
            };
            service.UploadPost(args, formData);
        }
    }

    PortfolioUpload() {
        this.ClearMessage();
        let inputFiles = ($("#input-b2") as any).fileinput();
        let data = inputFiles[0].files[0]

        if (data.name != "PortfolioTemplate.xlsx") {
            this.ShowErrorMessage = true;
            this.ErrorHeader = "Dosya aktarımı Başarısız.";
            this.ErrorMessage = "Dosya ismini kontrol ediniz.";
        }
        else {
            const formData = new FormData();
            formData.append('file', data);

            var service = new WebService(this.httpClient, this.router);
            var args = new serviceCallArgument();
            args.Controller = "Upload";
            args.Action = "PortfolioFile";

            args.FinallyHandler = (status, object) => {
                if (status == "success") {
                    this.ShowSuccessMessage = true;
                    this.SuccessHeader = "Dosya aktarımı Başarılı.";
                    this.SuccessMessage = "Sayfada güncel dataya ulaşabilirsiniz...";
                }
                else {
                    this.ShowErrorMessage = true;
                    this.ErrorHeader = "Dosya aktarımı Başarısız.";
                    this.ErrorMessage = object.Data;
                }

                let inputFiles2 = ($("#input-b2") as any).fileinput();
                inputFiles2.fileinput('clear');
            };
            service.UploadPost(args, formData);
        }
    }

    PerformaceUpload() {
        this.ClearMessage();
        let inputFiles = ($("#input-b3") as any).fileinput();
        let data = inputFiles[0].files[0]

        if (data.name != "OperasyonelPerformans.xlsx") {
            this.ShowErrorMessage = true;
            this.ErrorHeader = "Dosya aktarımı Başarısız.";
            this.ErrorMessage = "Dosya ismini kontrol ediniz.";
        }
        else {
            const formData = new FormData();
            formData.append('file', data);

            var service = new WebService(this.httpClient, this.router);
            var args = new serviceCallArgument();
            args.Controller = "Upload";
            args.Action = "PerformaceFile";

            args.FinallyHandler = (status, object) => {
                if (status == "success") {
                    this.ShowSuccessMessage = true;
                    this.SuccessHeader = "Dosya aktarımı Başarılı.";
                    this.SuccessMessage = "Sayfada güncel dataya ulaşabilirsiniz...";
                }
                else {
                    this.ShowErrorMessage = true;
                    this.ErrorHeader = "Dosya aktarımı Başarısız.";
                    this.ErrorMessage = object.Data;
                }

                let inputFiles2 = ($("#input-b3") as any).fileinput();
                inputFiles2.fileinput('clear');
            };
            service.UploadPost(args, formData);
        }
    }
}