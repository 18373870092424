import { Component, Inject, OnInit, ViewChild, AfterViewInit, ViewEncapsulation, TemplateRef } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { serviceCallArgument, WebService } from '../_communication/web-service';
import { FinancialQuarter, FinancialData } from '../_model';
import * as Chart from 'chart.js';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SelectComponent } from '../_ui-Component/select/select.component';
import { SelectQuarterComponent } from '../_ui-Component/select-quarter/select-quarter.component';

@Component({
    selector: 'app-financial',
    templateUrl: './financial.component.html',
    providers: [NgbModal],
    styleUrls: ['./financial.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
/** financial component*/
export class FinancialComponent implements AfterViewInit {
    @ViewChild('currencyComponent') currencyComponent: SelectComponent;
    @ViewChild('quarterComponent') quarterComponent: SelectComponent;

    financialQuarter: BehaviorSubject<FinancialQuarter>;
    financialData: BehaviorSubject<FinancialData>;
    note: string = "";
    subNote: string = "";
    subNote2: string = "";
    onlyyearend: string = "";
    headerData: string = ""
    currency: string = "";
    close: string = "";
    currencyData: any = [];
    currencySelectedItem: string = "";
    onlyYear: boolean = true;
    quarter: string = "";
    barGraphic: string = "";
    quarterData: any = [];
    quarterSelectedItem: string = "";

    headers: any = [];
    values: any = [];
    copyRightStatu = true;

    @ViewChild('content') modalContent: TemplateRef<any>;
    chartHeader: string = "";

    financialChartCanvas: any;
    financialChartCtx: any;
    financialChartData: Chart;

    constructor(private httpClient: HttpClient, private router: Router, private modalService: NgbModal) {

        let routingPath = this.router.url;

        let pagelanguageId = 1;

        if (routingPath == "/financial") {
            this.copyRightStatu = false;
            pagelanguageId = 2;
        }
            

        this.headerData = pagelanguageId == 1 ? "Temel Finansal Veriler" : "Financial Data";
        this.currency = pagelanguageId == 1 ? "Para Birimi" : "Currency";
        this.currencyData = pagelanguageId == 1 ? [{ code: "TRL", name: "Türk Lirası" }, { code: "USD", name: "Dolar" }, { code: "EUR", name: "Euro" }] : [{ code: "TRL", name: "Turkish Lira" }, { code: "USD", name: "Dollar" }, { code: "EUR", name: "Euro" }];
        this.currencySelectedItem = "EUR";
        this.barGraphic = pagelanguageId == 1 ? "Grafik" : "Graphic";
        this.onlyyearend = pagelanguageId == 1 ? "Sadece Yıl Sonu" : "Only Year End";
        this.quarter = pagelanguageId == 1 ? "Dönem" : "Quarter";
        this.close = pagelanguageId == 1 ? "Kapat" : "Close";
        this.note = pagelanguageId == 1 ? " * Finansal veriler son yayımlanan konsolide finansal tabloları esas almaktadır." : "* Financial data is based on the latest published consolidated financial statements.";
        this.subNote = pagelanguageId == 1 ? "** Net borç hesaplamasında, finansallarda, TFRS 16 standartı gereği Finansal Borçlarda gösterilen 'Kiralama İşlemlerinden Borçlar' kalemi düşülerek gösterilmiştir." : "** In the calculation of net debt, the item 'Lease Liabilities, which is shown in Financial Liabilities in accordance with TFRS 16 standard, is deducted.";
        this.subNote2 = pagelanguageId == 1 ? "*** FAVÖK rakamlarında; 1 Ocak 2019 tarihinden itibaren uygulanmaya başlanan TFRS 16 standartı gereği, 'Kiralama Giderleri' 2019 yılından itibaren hesaplamaya dahil edilmemektedir." : "*** In EBITDA figures; in accordance with the TFRS 16 standard, which has been implemented as of January 1, 2019, 'Lease Expenses' are not included in the calculation since 2019.";


        this.PopulateQuarter();
    }

    PopulateQuarter() {
        var service = new WebService(this.httpClient, this.router);
        var args = new serviceCallArgument();
        args.Controller = "Financial";
        args.Action = "QuarterList";
        args.JPostContent = {};

        args.FinallyHandler = (status, object) => {
            if (status == "success") {
                this.financialQuarter = new BehaviorSubject<FinancialQuarter>(JSON.parse(object.Data));
                this.quarterSelectedItem = this.financialQuarter.value[0]["code"];
                this.quarterData = this.financialQuarter.value;
                this.PopulateFinancial();
            }
            else {
            }
        };
        service.Post(args);
    }

    PopulateFinancial() {
        var service = new WebService(this.httpClient, this.router);
        var args = new serviceCallArgument();
        args.Controller = "Financial";
        args.Action = "FinancialData";
        args.JPostContent = { currency: this.currencySelectedItem, quarter: this.quarterSelectedItem, onlyYear : this.onlyYear };

        args.FinallyHandler = (status, object) => {
            if (status == "success") {
                this.financialData = new BehaviorSubject<FinancialData>(JSON.parse(object.Data));
                this.headers = this.financialData.value[0]["headers"];
                this.values = this.financialData.value[1]["values"];
            }
            else {
            }
        };
        service.Post(args);
    }

    ngAfterViewInit() {
        this.currencyComponent.name.subscribe((data) => {
            this.currencySelectedItem = data;
            this.PopulateFinancial();
        });

        this.quarterComponent.name.subscribe((data) => {
            this.quarterSelectedItem = data;
            this.PopulateFinancial();
        });
    }

    openModel(c1, cQ2, cQ3, cQ4, cQ5, cQ6, cV2, cV3, cV4, cV5, cV6) {
        this.chartHeader = c1;
        this.modalService.open(this.modalContent, { windowClass: 'financial-detail-content' })

        this.financialChartCanvas = document.getElementById('financialChart');
        this.financialChartCtx = this.financialChartCanvas.getContext('2d');
        this.financialChartData = new Chart(this.financialChartCtx, {
            type: 'bar',

            options: {
                legend: {
                    display: false
                },
                responsive: false,
                elements: {
                    point: {
                        radius: 0
                    }
                },
                scales: {
                    yAxes: [
                        {
                            ticks: {
                                beginAtZero: true,
                                fontColor: "black",
                                fontSize: 12,
                                fontStyle: "bold",
                                callback: function (label: number, index, labels) {
                                    return label.toLocaleString();
                                }
                            },
                        }
                    ],
                    xAxes: [
                        {
                            ticks: {
                                fontColor: "black",
                                fontSize: 12,
                                fontStyle: "bold",
                                callback: function (label: number, index, labels) {
                                    return label.toLocaleString();
                                }
                            },
                        }
                    ]
                },
                tooltips: {
                    callbacks: {
                        label: function (tooltipItem, data) {
                            let number: number = parseFloat(tooltipItem.yLabel.toString());
                            return number.toLocaleString();
                        }
                    }
                }
            }
        });

        this.financialChartData.data.labels = [cQ2, cQ3, cQ4, cQ5, cQ6];
        this.financialChartData.data.datasets = [{
            data: [cV2, cV3, cV4, cV5, cV6],
            backgroundColor: [
                "rgba(204, 45, 65, 1)",
                "rgba(204, 45, 65, 1)",
                "rgba(204, 45, 65, 1)",
                "rgba(204, 45, 65, 1)",
                "rgba(204, 45, 65, 1)"
            ],
            borderColor: [
                "rgba(204, 45, 65, 1)",
                "rgba(204, 45, 65, 1)",
                "rgba(204, 45, 65, 1)",
                "rgba(204, 45, 65, 1)",
                "rgba(204, 45, 65, 1)",
            ],
            borderWidth: 1
        }];
        this.financialChartData.update();
    }

    yearChange() {
        this.PopulateFinancial();
    }

    selectedCurrenyLoad(i: number): boolean {
        if (i > 0 && this.currencySelectedItem == "TRL")
            return true;
        else if (this.currencySelectedItem != "TRL" && i > 1)
            return true;
        else
            return false;
    }

}