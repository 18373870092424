import { BrowserModule } from '@angular/platform-browser';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatButtonModule } from "@angular/material/button";
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatTreeModule } from '@angular/material/tree';
import { AppComponent } from './app.component';
import { EquitySnapshotComponent } from './equity-snapshot/equity-snapshot.component';
import { FinancialComponent } from './financial/financial.component';
import { PortfolioComponent } from './portfolio/portfolio.component';
import { FileUploadComponent } from './file-upload/file-upload.component';
import { FileUploadLoginComponent } from './file-upload-login/file-upload-login.component';
import { ChartsModule } from 'ng2-charts';
import * as $ from 'jquery';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SelectComponent } from './_ui-Component/select/select.component';
import { SelectQuarterComponent } from './_ui-Component/select-quarter/select-quarter.component';
import { PerformanceComponent } from './performance/performance.component';
import { HomePageComponent } from './home-page/home-page.component';
import { HomePageRentalIncomeComponent } from './home-page-rental-income/home-page-rental-income.component';

@NgModule({
    declarations: [
        AppComponent,
        EquitySnapshotComponent,
        FinancialComponent,
        PerformanceComponent,
        PortfolioComponent,
        SelectComponent,        
        FileUploadLoginComponent,
        SelectQuarterComponent,
        FileUploadComponent,
        HomePageComponent,
        HomePageRentalIncomeComponent
    ],
    imports: [
        BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
        HttpClientModule,
        BrowserAnimationsModule,
        NgbModule,
        MatInputModule,
        MatNativeDateModule,
        MatInputModule,
        MatSelectModule,
        MatDatepickerModule,
        MatButtonModule,
        MatToolbarModule,
        MatIconModule,
        MatCheckboxModule,
        MatFormFieldModule,
        MatTreeModule,
        FormsModule,        
        RouterModule.forRoot([
            { path: 'snapshot', component: EquitySnapshotComponent, pathMatch: 'full' },
            { path: 'gorunum', component: EquitySnapshotComponent, pathMatch: 'full' },
            { path: 'financial', component: FinancialComponent },
            { path: 'finansal', component: FinancialComponent },
            { path: 'performance', component: PerformanceComponent },
            { path: 'performans', component: PerformanceComponent },
            { path: 'portfolio', component: PortfolioComponent },
            { path: 'portfoy', component: PortfolioComponent },
            { path: 'fileuploadlogin', component: FileUploadLoginComponent },
            { path: 'fileupload', component: FileUploadComponent },
            { path: 'fiyatperformans', component: HomePageComponent },
            { path: 'priceperformance', component: HomePageComponent },
            { path: 'kirageliri', component: HomePageRentalIncomeComponent },
            { path: 'rentalincome', component: HomePageRentalIncomeComponent },
        ]),
        ChartsModule
    ],
    providers: [],
    bootstrap: [AppComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule { }
